<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('ReportDrugBalance') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col cols="12" md="6" class="py-0" lg="3">
          <v-select
            v-model.trim="drugCategorySelect"
            :items="drugCategoryList"
            :label="$t('category')"
            outlined
            dense
            :no-data-text="$t('no_information')"
            :item-text="$i18n.locale"
            item-value="value"
            @change="addPayload"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="py-0" lg="3">
          <v-autocomplete
            v-model.trim="drugGroupSelect"
            :items="drugGroupList"
            outlined
            dense
            :label="$t('category')"
            :no-data-text="$t('no_information')"
            item-text="druggroup_name"
            item-value="druggroup_id"
            @change="addPayload"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0" md="6" lg="3">
          <v-autocomplete
            v-model.trim="drugTypeSelect"
            :items="drugTypeList"
            :label="$t('type')"
            outlined
            dense
            :no-data-text="$t('no_information')"
            item-text="drugtype_name"
            item-value="drugtype_id"
            @change="addPayload"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.drug_id`]="{ item }">
          <router-link
            v-if="item.drug_id_pri"
            :to="{ name: 'drugDetail', params: { id: item.drug_id_pri } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.drug_id }}
          </router-link>
        </template>
        <template v-slot:[`item.drugordert_total`]="{ item }">
          <span :class="item.drugordert_total == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.drugordert_total }}
          </span>
        </template>
        <template v-slot:[`item.cost`]="{ item }">
          <span :class="item.drugordert_total == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.cost }}
          </span>
        </template>
        <template v-slot:[`item.income`]="{ item }">
          <span :class="item.drugordert_total == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.income }}
          </span>
        </template>
        <template v-slot:[`item.profit`]="{ item }">
          <span :class="item.drugordert_total == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.profit }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import drugTypeForSearch from '@/@fake-db/data/drugTypeForSearch.json'
import drugGroup from '@/api/DrugManagement/drugGroup'
import drugType from '@/api/DrugManagement/drugType'
import { i18n } from '@/plugins/i18n'
import { reportDrugBalance, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'

export default {
  components: {
    Comeback,
  },
  setup() {
    const XLSX = require('xlsx')
    const drugTypeSelect = ref('')
    const drugTypeList = ref([])
    const drugGroupList = ref([])
    const drugCategorySelect = ref('')
    const drugGroupSelect = ref('')
    const drugCategoryList = ref(drugTypeForSearch.data)
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('drug_code'), value: 'drug_id', width: 80 },
      { text: i18n.t('drug_name'), value: 'drug_name', width: 200 },
      { text: i18n.t('group'), value: 'drug_category_name', width: 100 },
      { text: i18n.t('unit'), value: 'drug_unit', width: 100 },
      // {
      //   text: i18n.t('lot'),
      //   value: 'druglot_amount',
      //   width: 180,
      // },
      {
        text: i18n.t('cost_unit'),
        value: 'drugorder_cost',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('sale_price'),
        value: 'drug_price',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('import2x'),
        value: 'drugorder_in',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('transfer'),
        value: 'drugorder_out',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('sell2x'),
        value: 'drugorder_sold',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('balance2x'),
        value: 'drugordert_total',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('cost2x'),
        value: 'cost',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('income2x'),
        value: 'income',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('profit2x'),
        value: 'profit',
        width: 150,
        align: 'end',
      },
    ])

    const exportLoading = ref(false)
    onMounted(() => {
      addPayload()
    })

    const addPayload = () => {
      payload.value = {
        drug_category_id: drugCategorySelect.value,
        druggroup_id: drugGroupSelect.value,
        drugtype_id: drugTypeSelect.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { data } = await reportDrugBalance(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    drugGroup
      .drugGroupList({
        searchtext: '',
        drug_category_id: '',
        druggroup_status_id: '1',
      })
      .then(res => {
        drugGroupList.value = res.data
        drugGroupList.value.unshift({
          druggroup_id: '',
          druggroup_name: i18n.t('all_categories'),
        })
      })

    drugType
      .drugTypeList({
        searchtext: '',
        drug_category_id: '',
        drugtype_status_id: '1',
      })
      .then(res => {
        drugTypeList.value = res.data
        drugTypeList.value.unshift({
          drugtype_id: '',
          drugtype_name: i18n.t('all_types'),
        })
      })

    const exportExcel = async () => {
      exportLoading.value = true
      let listdata1 = JSON.parse(JSON.stringify(dataTableList.value))
      let dataExport = listdata1.map((item, i) => {
        delete item.drug_id_pri
        delete item.druglot_name
        delete item.druglot_date
        delete item.druglot_amount

        return { number: i + 1, ...item }
      })
      dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport)))

      const fileName = `${i18n.t('ReportDrugBalance')} ${i18n.t('all')}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${i18n.t('ReportDrugBalance')} ${i18n.t('all')}`],
        [
          '#',
          `${i18n.t('drug_code')}`,
          `${i18n.t('drug_name')}`,
          `${i18n.t('group')}`,
          `${i18n.t('unit')}`,
          // `${i18n.t('lot')}`,
          `${i18n.t('cost_unit')}`,
          `${i18n.t('sale_price')}`,
          `${i18n.t('import2x')}`,
          `${i18n.t('transfer')}`,
          `${i18n.t('sell2x')}`,
          `${i18n.t('balance2x')}`,
          `${i18n.t('cost2x')}`,
          `${i18n.t('income2x')}`,
          `${i18n.t('profit2x')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'drug_id',
          'drug_name',
          'drug_category_name',
          'drug_unit',
          // 'druglot_amount',
          'drugorder_cost',
          'drug_price',
          'drugorder_in',
          'drugorder_out',
          'drugorder_sold',
          'drugordert_total',
          'cost',
          'income',
          'profit',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 14 } }]
      const wscols = [
        { wch: 6 },
        { wch: 8 },
        { wch: 25 },
        { wch: 15 },
        { wch: 12 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('ReportDrugBalance')}`.replace('/', '-'))

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      drugTypeSelect,
      drugTypeList,
      drugGroupList,
      drugCategorySelect,
      drugGroupSelect,
      drugCategoryList,
      payload,
      loading,
      dataTableList,
      options,
      columns,
      exportLoading,
      mdiFileExcelOutline,
      addPayload,
      exportExcel,
    }
  },
}
</script>
